import { Backdrop, CircularProgress, Typography } from "@mui/material";
const Loader = () => {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" component="div" sx={{ marginTop: 2 }}>
            London Underwriters
          </Typography>
        </div>
      </Backdrop>
    );
  };
  
  export default Loader