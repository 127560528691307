"use client"
import Loader from "@/components/dashboard-pages/Loader";
import Wrapper from "@/layout/Wrapper";
import { API_REST_GET_ALIVE, API_REST_GET_MENU, SetErrorAgents, getToken } from "@/utils/api-rest";
import { URL_AGENT_ERROR, URL_SERVER_ERROR } from "@/utils/constants";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMenu } from '@/features/menu/menuSlice'
import { AxiosError } from "axios";
import { useRouter } from 'next/navigation'
export default function Page() {
 
  const [MenuDasboard,setMenuDashboard]= useState([])
  const router = useRouter();
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading, error, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [erroGeneral, setErrorGeneral] = useState(null);

  const [token,setToken] = useState( typeof localStorage != "undefined" ? localStorage.getItem("tokenInfo") : null);
  var IntervalNumber = null
  useEffect(() => {
    var FN_UPDATEtOKEN = null
    if (!isLoading && isAuthenticated) {
      getToken(getAccessTokenSilently, logout,setToken);
      FN_UPDATEtOKEN = setInterval(function () { getToken(getAccessTokenSilently,logout,setToken); }, 60000);
    }
   
    return () => {
      clearInterval(FN_UPDATEtOKEN)
    }
  }, [isLoading])

  const LoadApis = async ()=>{
    try {
   
      await API_REST_GET_MENU({ agent_id: user?.uid},setMenuDashboard)

      setErrorGeneral(null)
    } catch (error) {
     
      if(error instanceof AxiosError){
        if(error.code == 'ERR_NETWORK'){
          setErrorGeneral(error.message);
        }
        else{
          setErrorGeneral(error.response.data);
        }
        
      }

    }
    
  }
  useEffect(() => {
    if (erroGeneral) {
      IntervalNumber = setInterval(() => { LoadApis() }, 1000)
    }else{
      clearInterval(IntervalNumber)
    }
    return () => { clearInterval(IntervalNumber) }
  }, [erroGeneral])
  useEffect(() => {
    if (MenuDasboard && MenuDasboard.length > 0) {
      dispatch(setMenu(MenuDasboard));
      router.push(MenuDasboard[0]?.routePath || '/londonuw-dashboard/insurtech/dashboard')
      return 
    }
  }, [MenuDasboard])
  useEffect( () => {if(token && !isLoading)LoadApis();}, [token,isLoading])




  const LoginAction = async ()=>{
    try {
      await API_REST_GET_ALIVE().catch(e=>e)
    } catch (error) {
      Sentry.captureException(error);
    }
    
    loginWithRedirect()
  }
  useEffect(() => {
    SetErrorAgents("")
   
    if (!isLoading && error) {
      localStorage.removeItem("tokenInfo");
      SetErrorAgents(`${error}`)

      if (error.error_description == "Please Verify Email") {
        return window.location.replace('/verify')
      }
      else if(
        error.error_description.includes("Server Error") || 
        error.error_description.includes("Script execution time exceeded")){
        Sentry.captureException(error);
        return  logout({logoutParams:{returnTo:URL_SERVER_ERROR}}) 
      }
      else {
    
       return  logout({logoutParams:{returnTo:URL_AGENT_ERROR}}) 
      }
    
      
    }
    if (!isLoading && !isAuthenticated && !error) {
      LoginAction()
      return()=>{}
    }
    return()=>{}
  }, [isLoading, error])

  //console.log(MenuDasboard,token,isLoading)
  return (
    <Wrapper><Loader /></Wrapper>
  );
}
